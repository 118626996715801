import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class ProgressBar {
    constructor() {
        this.init();
    }

    init() {
        const progressBar = document.querySelector('#progress-bar');
        const progress = progressBar.querySelector('progress');
        const progressIcon = progressBar.querySelector('.progress-icon');

        gsap.to(progress, {
            value: 100,
            ease: 'none',
            scrollTrigger: { scrub: 0.3 }
        });


        gsap.to(progressIcon, {
            x: (progressBar.offsetWidth - progressIcon.offsetWidth),
            ease: 'none',
            scrollTrigger: { scrub: 0.3 }
        });

        const _root = this;

        window.addEventListener('scroll', function(e) {
            _root.onScroll(progressBar);
        });
    }

    onScroll(_pb) {
        
        const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        let _wh = (document.body.scrollHeight - window.innerHeight) - document.querySelector('#footer').offsetHeight;

        if ('undefined' != typeof _pb && null != _pb) {
            _wh <= scrollTop ? _pb.classList.add('active') : _pb.classList.remove('active');
        }

    }
    

}
